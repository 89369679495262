<template>
  <div id="app">
    <v-app>
      <v-main>
        <router-view name="SideBar"></router-view>
        <router-view name="MainContent"></router-view>
        <ErrorReportDialog />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import ErrorReportDialog from '@/components/ErrorReportDialog'
export default {
  beforeMount() {
    this.$store.commit('setNavigationDrawer', !this.$vuetify.breakpoint.mobile)
  },
  components: {
    ErrorReportDialog
  }
}
</script>

<style scoped>
#app {
  background-color: #F7F7F7;
}

</style>

<style>
/* The --v-navigation-drawer-zindex is here for reference and has no effect */
:root {
  --v-navigation-drawer-zindex: 1;
  --grid-group-header-zindex: 2;
  --grid-toolbar-zindex: 3;
  --top-of-v-navigation-drawer-zindex: 5;
}

.v-main {
  transition: none !important;
  max-height: 100%;
}

.v-application--wrap {
  height: 100vh;
}

/* preventing p tags from overflowing their parent */
p {
  min-width: 1px;
}

/* preventing underlaying content to be selected while scrolling.
used e.g. while dragging kanban a card */
.no-select {
  user-select: none;
}
</style>
